import * as bootstrap from "bootstrap";

document.addEventListener("DOMContentLoaded", () => {
    const navbar = document.getElementById("menu");
    const navbarObserver = new ResizeObserver(() => {
        document.documentElement.style.scrollPaddingTop = `${navbar.clientHeight}px`
    })
    navbarObserver.observe(navbar)

    const topCompliants = document.getElementsByClassName("top-compliant");
    if (topCompliants.length == 0) return;

    const el = topCompliants[0];


    const backButtonBox = document.getElementById("global-back-button-box");

    const obs = new ResizeObserver(() => {
        el.style.minHeight = new String(window.innerHeight - navbar.clientHeight - backButtonBox.clientHeight - 1) + "px";
    });

    obs.observe(navbar);
    obs.observe(backButtonBox);
    obs.observe(el);
});